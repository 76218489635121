.order-box {
    margin-top: 0px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: auto;

    &-head {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 0 2rem;

        .box-header-img {
            width: 40px;
            height: 40px;
            background-color: #303030;
            @include center;
            border-radius: 50%;

            img {
                width: 30px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 10px;
            right: 0;
        }

        &.open-type {
            &::before {
                background: #FFB300;

            }
        }

        &.last-type {
            &::before {
                background: #2BAE7D;

            }
        }

        &-reset-icon {
            background-color: #2BAE7D;
        }

        &-show-all-icon {
            background-color: #2BAE7D;
            padding: 1.5rem 2rem;
            padding: 1rem 2rem;
            border: 1px solid #373737;
            border-radius: 5px;
            width: 110px;
            text-align: center;
        }

    }

    &-details {
        margin-top: 20px;
        font-size: 1.3rem;

        li {


            &:nth-child(even) {
                background-color: #343434;
            }

            >div:first-child {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                padding: 11px 2rem;
            }

            >div:nth-child(2) {
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 100%;
                justify-content: space-evenly;
                padding: 11px 2rem;
            }
        }

        &-coin {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            padding: 0 15px;
        }

        &-cost {
            margin: 0 10px;
        }

        &-arrow {
            width: 20px;
            border-radius: 50%;
            height: 20px;
            @include center;
        }

        &-cancel-icon {
            width: 30px;
            height: 30px;
        }

        &-status-box {
            padding: 1rem 2rem;
            border: 1px solid #373737;
            border-radius: 5px;
            width: 110px;
            text-align: center;
        }

    }
}

.p15 {
    padding: 5px !important;
}
.soorandn
{
    animation-name: sooran;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
@keyframes sooran {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

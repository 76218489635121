$icomoon-font-family: "Iconly---Bold" !default;
$icomoon-font-path: "fonts" !default;

$iconly-boldActivity: "\e900";
$iconly-boldUser: "\e901";
$iconly-boldUser1: "\e902";
$iconly-boldAdd-User: "\e903";
$iconly-boldArrow---Down-2: "\e904";
$iconly-boldArrow---Down-3: "\e905";
$iconly-boldArrow---Down-Circle: "\e906";
$iconly-boldArrow---Down-Square: "\e907";
$iconly-boldArrow---Down: "\e908";
$iconly-boldArrow---Left-2: "\e909";
$iconly-boldArrow---Left-3: "\e90a";
$iconly-boldArrow---Left-Circle: "\e90b";
$iconly-boldArrow---Left-Square: "\e90c";
$iconly-boldArrow---Left: "\e90d";
$iconly-boldArrow---Right-2: "\e90e";
$iconly-boldArrow---Right-3: "\e90f";
$iconly-boldArrow---Right-Circle: "\e910";
$iconly-boldArrow---Right-Square: "\e911";
$iconly-boldArrow---Right: "\e912";
$iconly-boldArrow---Up-2: "\e913";
$iconly-boldArrow---Up-3: "\e914";
$iconly-boldArrow---Up-Circle: "\e915";
$iconly-boldArrow---Up-Square: "\e916";
$iconly-boldArrow---Up: "\e917";
$iconly-boldBag-2: "\e918";
$iconly-boldBag: "\e919";
$iconly-boldBookmark: "\e91a";
$iconly-boldBuy: "\e91b";
$iconly-boldCalendar: "\e91c";
$iconly-boldCall-Missed: "\e91d";
$iconly-boldCall-Silent: "\e91e";
$iconly-boldCall: "\e91f";
$iconly-boldCalling: "\e920";
$iconly-boldCamera: "\e921";
$iconly-boldCategory: "\e922";
$iconly-boldChart: "\e923";
$iconly-boldChat: "\e924";
$iconly-boldClose-Square: "\e925";
$iconly-boldDanger: "\e926";
$iconly-boldDelete: "\e927";
$iconly-boldDiscount: "\e928";
$iconly-boldDiscovery: "\e929";
$iconly-boldDocument: "\e92a";
$iconly-boldDownload: "\e92b";
$iconly-boldEdit-Square: "\e92c";
$iconly-boldEdit: "\e92d";
$iconly-boldFilter-2: "\e92e";
$iconly-boldFilter: "\e92f";
$iconly-boldFolder: "\e930";
$iconly-boldGame: "\e931";
$iconly-boldGraph: "\e932";
$iconly-boldHeart: "\e933";
$iconly-boldHide: "\e934";
$iconly-boldHome: "\e935";
$iconly-boldImage-2: "\e936";
$iconly-boldImage: "\e937";
$iconly-boldInfo-Circle: "\e938";
$iconly-boldInfo-Square: "\e939";
$iconly-boldLocation: "\e93a";
$iconly-boldLock: "\e93b";
$iconly-boldLogin: "\e93c";
$iconly-boldLogout: "\e93d";
$iconly-boldMessage: "\e93e";
$iconly-boldMore-Circle: "\e93f";
$iconly-boldMore-Square: "\e940";
$iconly-boldNotification: "\e941";
$iconly-boldPaper-Download: "\e942";
$iconly-boldPaper-Fail: "\e943";
$iconly-boldPaper-Negative: "\e944";
$iconly-boldPaper-Plus: "\e945";
$iconly-boldPaper-Upload: "\e946";
$iconly-boldPaper: "\e947";
$iconly-boldPassword: "\e948";
$iconly-boldPlay: "\e949";
$iconly-boldPlus: "\e94a";
$iconly-boldProfile: "\e94b";
$iconly-boldScan: "\e94c";
$iconly-boldSearch: "\e94d";
$iconly-boldSend: "\e94e";
$iconly-boldSetting: "\e94f";
$iconly-boldShield-Done: "\e950";
$iconly-boldShield-Fail: "\e951";
$iconly-boldShow: "\e952";
$iconly-boldStar: "\e953";
$iconly-boldSwap: "\e954";
$iconly-boldTick-Square: "\e955";
$iconly-boldTicket-Star: "\e956";
$iconly-boldTicket: "\e957";
$iconly-boldTime-Circle: "\e958";
$iconly-boldTime-Square: "\e959";
$iconly-boldUnlock: "\e95a";
$iconly-boldUpload: "\e95b";
$iconly-boldVideo: "\e95c";
$iconly-boldVoice-2: "\e95d";
$iconly-boldVoice: "\e95e";
$iconly-boldVolume-Down: "\e95f";
$iconly-boldVolume-Off: "\e960";
$iconly-boldVolume-Up: "\e961";
$iconly-boldWallet: "\e962";
$iconly-boldWork: "\e963";


.default-box {
    background-color: $color-grey-1;
    border-radius: 5px;
    padding: 13px;
    margin-bottom: 15px;
}

.box-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .box-header-title {
        font-size: 1.2rem;
        color: #fff;
        width: 100%;
    }

    .box-header-img {
        margin-left: 5px;
        img {
            width: 15px;
        }
    }

}
.kif
{
    padding: 5px 10px;
    width:  -webkit-fill-available ;
    background-color: rebeccapurple;
    border-radius: 5px;
    text-align: center;
}
.wallet-notification-box {
    margin: 20px;
    min-height: 500px;

    &-head {

        display: flex;
        justify-content: space-between;
        align-items: center;
        


        .box-header-img {
            padding: 15px;
            @include center;
            background-color: #3b3b3b;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 5px;

            img {
                width: 15px;
            }
        }

        position: relative;

        &::before {
            content: "";
            position: absolute;
            background: #FFB300;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 6px;
            right: -14px;
        }
    }

    &-details {
        display: flex;
        justify-content: space-between;

        @media (max-width: 992px) {
            flex-direction: column;
        }

        margin: 20px 6px;
        list-style: none;

        &-tab {
            @media (max-width: 992px) {
                display: flex;
                justify-content: space-around;
            }

            li {
                display: flex;
                

                @media (max-width: 992px) {
                    flex-direction: column;
                }

                align-items: center;
                padding: 20px 0;
                font-size: 12px;
                cursor: pointer;

                p {
                    margin: 0 10px;

                    @media (max-width: 992px) {
                        padding-top: 15px;
                    }
                }

            }
        }

        &-items {
            @media (min-width: 992px) {
                width: 80%;
            }

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                padding: 20px 0;

                &:not(:last-child) {
                    border-bottom: 1px solid #393939;
                }

            }

            &-date {
                display: flex;
                align-items: center;
                padding: 0 15px;

                span {
                    margin: 0 3px;
                }

                color: #808080;
            }
        }
    }
}
.Offers-box {
    background-color: #202020 !important;
    overflow: auto;
    margin: 0;

    @media (max-width: 992px) {
        border-radius: 0 0 1.5rem 1.5rem;
        padding-top: 4rem !important;
    }

    .table-body {
        &:not(:last-child) {
            border-bottom: 0.5px solid #272727;
        }
    }

    &.type-sell .table-head {
        background-image: linear-gradient(to right,
                rgba(229, 57, 57, 0.288),
                rgba(47, 49, 50, 0));
    }

    &.type-buy .table-head {
        background-image: linear-gradient(to right,
                rgba(6, 157, 135, 0.288),
                rgba(47, 49, 50, 0));
    }


    &.type-sell .box-header-img {
        width: 40px;
        height: 35px;
        background-color: #E63D3D;
        @include center;
        border-radius: 50%;

        img {
            width: 12px;
        }
    }

    &.type-buy .box-header-img {
        width: 40px;
        height: 35px;
        background-color: #069D87;
        @include center;
        border-radius: 50%;

        img {
            width: 12px;
        }
    }

    .Offers-box-details {
        overflow-y: scroll;

        min-height: 50px;
        max-height: 250px;

        overflow-x: hidden;
        direction: ltr;

        .table-body {
            direction: rtl;
            grid-template-columns: 98px 67px 70px;
        }

        &::-webkit-scrollbar {
            width: 3px;
            border-radius: 1rem;
            height: 180px;
        }

        &::-webkit-scrollbar-track {
            background: rgb(39, 39, 39);
        }

        &::-webkit-scrollbar-thumb {

            background: #363636;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }


    }
}
.react-switch
{
    color: #222222;
    
}
.trade-bitcoin-box {
    padding: 0;
    overflow: hidden;
    border-radius: 0px 0px 10px 10px;

    @media (max-width: 992px) {
        border-radius: 0 0 1.5rem 1.5rem;
        padding-top: 4rem !important;
    }

    &-head {
        padding: 10px 15px;

        img {
            width: 20px;
        }
        &-title{
            display: flex;
            justify-content: space-between;
        }
    }

    &-options-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 15px;
        font-size: 12px;
        border-bottom: 1px solid #393939;

        button {
            color: #fff;
            border: none;
            border-radius: 5px;
            padding: 5px 15px;

            &:focus {
                outline: none;
            }
        }

        &-value {
            display: flex;

            span {
                margin: 0 2px;
            }
        }
    }

    &-options-input {


        >div {
            display: grid;
            align-items: center;
            grid-template-columns: 2fr 3fr 1fr;
            padding: 0 10px;
            background: #222222;
            margin: 10px;
            border-radius: 5px;
            font-size: 11px;

            p {
                text-align: right;
                margin-right: 26px;
            }



            input {
                width: 100%;
                margin: 0 0px;
                padding: 8px 8px;
                border-radius: 0;

                background-color: #414141;

                text-align: right;
                border: none;

                font-size: 12px;
                color: #fff;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    color: #fff !important;
                    opacity: 1 !important;
                    font-family: "Samim", sans-serif;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #fff !important;
                }

                &:placeholder-shown {
                    color: #fff !important;
                }
            }
        }

    }

    &-details-item {
        display: flex;
        padding: 5px 15px;
        font-size: 12px;

        &:not(:last-child) {
            border-bottom: 1px solid #393939;

        }

        &-key {
            width: 135px;
            text-align: right;
        }

        &-value {
            display: flex;

            span {
                margin: 0 2px;
            }
        }

    }

    &-btn {
        margin: 10px 15px;

        button {
            border: none;
            border-radius: 5px;
            padding: 5px 15px;
            width: 100%;

            color: #fff;

            &:focus {
                outline: none;
            }
        }
    }
}
.price-chart-box {
    margin: 1rem 2rem;
    margin-top: 10px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;

    &-head {
        flex-direction: row-reverse;
        justify-content: space-between;
        position: relative;
        padding: 0 20px;

        .box-header-img {
            width: 40px;
            height: 40px;
            background-color: #373737;
            @include center;
            border-radius: 50%;

            img {
                width: 20px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            background: #099CFE;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 7px;
            right: 0;
        }
    }

    &-widget {
        height: 500px;
    }
}
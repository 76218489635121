.user-account-status-box {
    display: flex;
    flex-direction: column;
    grid-row: 1/3;

    &-head {
        display: flex;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            background: #ffb300;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 7px;
            right: -15px;
        }

        .box-header-img {
            width: 40px;
            height: 40px;
            background-color: #373737;
            @include center;
            border-radius: 50%;
            img {
                width: 20px;
            }
        }

       
    }

    &-level {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4rem 0;

        &-icon {
            position: relative;

            &-shadow {
                width: 85px;
                height: 42px;
                position: absolute;
                background: #303030;
                top: 50px;
                transform: rotate(+38deg);
                border-bottom-left-radius: 50%;
                border-bottom-right-radius: 50%;
                box-shadow: -3px -3px 5px 1px #303030;
                z-index: 99;
            }
        }

        p {
            z-index: 9;
            color: #FFB300;
            font-weight: bold;
            font-size: 1.5rem;
        }
    }

    &-details {
        list-style: none;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 0;

            &:not(:last-child) {
                border-bottom: 1px solid #393939;

            }

            font-size: 1.2rem;

            p {
                span {
                    margin: 0 2px;
                }
            }

            .user-account-status-box-details-key {
                display: flex;
                align-items: center;
            }

            .user-account-status-box-details-value {
                display: flex;
            }
        }

        &-dot {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-left: 10px;
        }
    }
    &-btn{
        background-color: #FFB300;
        border: none;
        color: #fff;
        font-size: 1.2rem;
        padding: 1.5rem 2rem;
        margin: 1rem auto;
        margin-top: 2rem;
        font-family: "Samim", sans-serif;
        border-radius: 5px;
        width: 100%;
    
        &:focus {
            outline: none;
            border: none;
        }
    }
}

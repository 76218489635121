$color-grey-1: #303030;
$color-grey-2: #262626;
$color-primary: #0090EF;



$screens: (
  ("xxs", 0),
  ("xs", 544px),
  ("sm", 768px),
  ("md", 992px),
  ("lg", 1300px),
  ("xl", 1600px),
  ("xxl", 1900px)
);




.header {
    background-color: $color-grey-1;
    display: grid;
    padding: 1rem 2rem;
    height: 51px;
    grid-template-columns: 90% 10%;

    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    &-part {
        display: flex;

        a {
            align-items: center;
            display: flex;
        }

        .header-logo {
            display: flex;
            align-items: center;

            h3 {
                font-size: 1.8rem;
                color: #fff;
                margin: 0 1rem;
            }

            img {
                width: 25px;
            }

        }

        .header-nav {
            display: flex;
            align-items: center;

            ul {
                display: flex;
                align-items: center;
                margin: 0 2rem;

                li {
                    display: flex;
                    align-items: center;
                    margin-right: 3rem;

                    p {
                        margin-right: 10px;
                        font-size: 1.4rem;
                    }

                    img {
                        width: 16px;
                    }
                }
            }
        }
    }

    &-options {
        display: flex;
        align-items: center;

        a {
            outline: none;
        }

        &-icon {
            padding: 8px;
            border-radius: 50%;
            border-width: 0.2px;
            border-style: solid;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 8px;

            img {
                width: 26px;
                border-radius: 5px;
            }

            &-setting,
            &-avatar {
                border-color: #7d7676;
            }

            &-notification {
                border-color: #ffb3003d;
            }
        }

        &-item-active {
            .header-options-icon {
                background-color: #099CFE;
                border-color: transparent;
            }
        }

        &-profile {
            display: flex;
            align-items: center;

            p {
                margin-left: 3rem;
                font-size: 12.5px;
            }
        }
    }
}

.header-auth {
    background-color: $color-grey-1;
    display: flex;
    padding: 1rem 2rem;
    height: 51px;
    justify-content: space-between;

    width: 100%;
    z-index: 1000;

    &-part {
        display: flex;

        a {
            align-items: center;
            display: flex;
        }

        .header-auth-logo {
            display: flex;
            align-items: center;

            h3 {
                font-size: 1.8rem;
                color: #fff;
                margin: 0 1rem;
            }

            img {
                width: 25px;
            }

        }

        .header-auth-nav {
            display: flex;
            align-items: center;

            ul {
                display: flex;
                align-items: center;
                margin: 0 2rem;

                li {
                    display: flex;
                    align-items: center;
                    margin-right: 3rem;

                    p {
                        margin-right: 10px;
                        font-size: 1.4rem;
                    }

                    img {
                        width: 13px;
                    }
                }
            }
        }
    }

    &-options {
        display: flex;
        align-items: center;

        a {
            outline: none;
        }

        &-icon {
            padding: 8px;
            border-radius: 50%;
            border-width: 0.2px;
            border-style: solid;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 8px;

            img {
                width: 15px;
            }

            &-setting,
            &-avatar {
                border-color: #7d7676;
            }

            &-notification {
                border-color: #ffb3003d;
            }
        }

        &-item-active {
            .header-options-icon {
                background-color: #099CFE;
                border-color: transparent;
            }
        }

        &-profile {
            display: flex;
            align-items: center;

            p {
                margin-left: 3rem;
                font-size: 12.5px;
            }
        }
    }
}

.footer-copy-right {
    position: absolute;
    margin: auto;
    bottom: 0;
    margin-bottom: 10px;
    width: 100%;

    p {
        display: flex;
        justify-content: center;
    }
}

.nav-bottom {
    @media (min-width: 992px) {
        display: none;
    }

    height: 80px;
    position: fixed;
    bottom: 0;
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color:#303030;
    z-index: 1000;

    li {
        padding: 15px 0;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                color: white;
                font-size: 20px;
                padding-top: 10px;
                padding-bottom: 10px;
            }

        }

        .nav-bottom-item-active {

            span,
            p {
                color: #FFB300;
            }
        }


    }
}
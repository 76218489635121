.tab {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    list-style-type: none;
    color: #ffffff;
    border-radius: 5px 5px 0 0;
    width: 100%;

    .tab-item {
        cursor: pointer;
        padding: 15px 10px;
        text-align: center;
        flex: 60%;
        font-size: 14px;
        &:hover {
            color: #ffb300
        }

        &.tab-item-active {
            opacity: 1;
        }
    }
}
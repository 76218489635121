.notification-box {
    margin: 1rem 0;

    &-head {
        display: flex;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            background: #1B7064;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 7px;
            right: -15px;
        }

        .box-header-img {
            width: 40px;
            height: 40px;
            background-color: #373737;
            @include center;
            border-radius: 50%;

            img {
                width: 10px;
            }
        }

    }

    &-details {
        list-style: none;
        margin-top: 17px;

        li {
            display: flex;
            align-items: center;
            padding: 13.5px 0;
            font-size: 1rem;

            &:not(:last-child) {
                border-bottom: 1px solid #393939;

            }

            img {
                width: 10px;
            }

            p {
                margin-right: 8px;
                text-align: right;
                white-space: nowrap;
            }

        }
    }
}
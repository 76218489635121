@import "./variable";
@import "./mixin";

@font-face {
    font-family: Samim;
    src: url("../font/Samim.eot");
    src: url("../font/Samim.eot?#iefix") format("embedded-opentype"),
        url("../font/Samim.woff2") format("woff2"),
        url("../font/Samim.woff") format("woff"),
        url("../font/Samim.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: Samim;
    src: url("../font/Samim-Bold.eot");
    src: url("../font/Samim-Bold.eot?#iefix") format("embedded-opentype"),
        url("../font/Samim-Bold.woff2") format("woff2"),
        url("../font/Samim-Bold.woff") format("woff"),
        url("../font/Samim-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: Samim;
    src: url("../font/Samim-Medium.eot");
    src: url("../font/Samim-Medium.eot?#iefix") format("embedded-opentype"),
        url("../font/Samim-Medium.woff2") format("woff2"),
        url("../font/Samim-Medium.woff") format("woff"),
        url("../font/Samim-Medium.ttf") format("truetype");
    font-weigh: 500;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    direction: rtl;
    font-size: 62.5%;
    font-family: "Samim", sans-serif;
    scroll-behavior: smooth;

    --white-color: #fff;
    --page-title-color: #7d7d7d;
}

.login_err {
    color: #fff;
    background-color: deeppink;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    padding: 5px;
}

body {
    box-sizing: border-box;
    overflow-x: hidden;
    background-color: $color-grey-2;

    .app-dashboard-container {
        background-color: $color-grey-2;
    }

    .app-dashboard-container-content {
        margin-top: 44px;

        @media (max-width: 992px) {
            margin-bottom: 80px;
        }

        overflow-x: hidden;
    }

    .trade-container-content {
        @media (min-width: 992px) {
            overflow-y: scroll;
            direction: ltr;

            >div {
                direction: rtl;
            }

            &::-webkit-scrollbar {
                width: 3px;
                border-radius: 1rem;
                height: 180px;
            }

            &::-webkit-scrollbar-track {
                background: rgb(39, 39, 39);
            }

            &::-webkit-scrollbar-thumb {

                background: #363636;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }

}

.list {
    list-style: none;
}

a,
a:link,
a:active {
    text-decoration: none;
}

p {
    color: #fff;
}

button {
    cursor: pointer;
    border: none;
    font-family: "Samim", sans-serif;
    color: #fff;
    white-space: nowrap;

    &:focus {
        border: none;
        outline: none;
    }
}

p::selection {
    background: #fff;
    color: rgb(0, 0, 0);
}

.container {
    max-width: 130rem;
    overflow: hidden;
    margin: 0 auto;
    padding: 0 2rem;
}

.grey-icon {
    width: 40px;
    height: 40px;
    background-color: #373737;
    @include center;
    border-radius: 50%;
    cursor: pointer;
}

.d-flex {
    display: flex;
    align-items: center;
}

.blue-spot {
    width: 8px;
    height: 8px;
    background-color: #0093F3;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 2px;
}


.red-gradient-bg {
    background-image: linear-gradient(to right, rgba(229, 57, 57, 0.288), rgba(47, 49, 50, 0));
}

.red-bg {
    background-color: #E53939;
}

.green-gradient-bg {
    background-image: linear-gradient(to right, rgba(10, 183, 158, 0.688), rgba(47, 49, 50, 0));
    color: #051a34 !important;

}
.green-gradient-bg1 {
    background-image: linear-gradient(to top, rgba(10, 183, 158, 0.688), rgba(47, 49, 50, 0));
    color: #051a34 !important;

}
.green-bg {
    background-color: #258D7E
}


.grey-bg {
    background-color: #3B3B3B;
}
::-webkit-scrollbar {
    width: 1px !important;
    //border-radius: 1rem;
}
.modal-error {
    color: #E53939;
    font-size: 35px;
    margin-bottom: 10px;
}

.modal-success {
    color: #33f0d3;
    font-size: 35px;
    margin-bottom: 10px;
}
.order-history {
    margin-top: 20px;
    padding: 0;

    &-head {
        padding: 20px;
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 992px) {
            flex-direction: column;
            align-items: center;

            >div:nth-child(1) {
                width: 100%;
                padding: 15px 0;
            }
        }

        .box-header-img {
            padding: 15px;
            @include center;
            background-color: #3b3b3b;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 5px;

            img {
                width: 15px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            background: #FFB300;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 29px;
            right: 0;
        }

        &-options {
            display: grid;

            @media (min-width: 992px) {
                grid-template-columns: auto auto auto auto;
            }

            @media (max-width: 992px) and (min-width: 300px) {
                grid-template-columns: auto auto;
            }

            @media (max-width: 300px) {
                grid-template-columns: auto;
            }

            grid-gap: 10px;

            select {

                background-color: #414141;
                color: #fff;
                text-align: right;
                font-family: "Samim", sans-serif;
                direction: rtl;
                width: 100%;
                border-radius: 5px;
                border: none;
                padding: 13px 20px;
                border-left: 20px solid #414141;

                svg {
                    margin-left: 10px;
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }

    &-details {
        margin-top: 30px;
        width: 100%;

        &-head {
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            align-content: center;
            justify-items: center;
            padding: 20px;
            font-size: 12px;

            p {
                color: #FFB300;
            }
        }

        &-item {
            @media (min-width: 992px) {
                display: grid;
                grid-template-columns: repeat(9, 1fr);
                align-content: center;
                justify-items: center;
                padding: 2px 20px ;
                font-size: 12px;
            }


            &-flex {
                display: flex;

                p {
                    padding: 0 5px;
                }
            }

            &:nth-child(even) {
                background-color: #3e3e40;
            }

            &-child {
                display: flex;
                padding: 0px 20px;

                td {
                    display: flex;
                    justify-content: space-between;
                    align-content: center;
                    justify-items: center;
                    padding: 10px;
                    font-size: 12px;
                    width: 50%;

                    p:nth-child(1) {
                        color: #FFB300;
                    }
                }
            }
        }
    }
}
@mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
}


@mixin respond($breakpoint) {
    @if $breakpoint == smallest-phone {
        @media (max-width : 25em) //400px
        {  @content };
         
    }
    @if $breakpoint == small-phone {
        @media (max-width : 31.25em) //500px
        {  @content };
         
    }
    @if $breakpoint == phone {
        @media (max-width : 37.5em) //600px
        {  @content };
         
    }
    @if $breakpoint == big-phone {
        @media (max-width : 43.75em) //700px
        {  @content };
         
    }
   @if $breakpoint == tab-port {
        @media  (max-width : 56.25em){ //900
            @content
        };
         
    }
    @if $breakpoint == tab-land {
        @media  (max-width :75em){  //1200
            @content
            
        };
         
    }
    @if $breakpoint == big-desktop {  //1800
        @media  (min-width : 112.5em){
            @content
            
        };
         
    }
};

.pm input, .pm select
{
    padding: 3px 15px;
    width: -webkit-fill-available;
    margin: 0px 10px;
    border-radius: 5px;
    background-color: #444343;
    
    color: #fff;
    font-family: 'Samim'; 
}
.JDatePicker
{
  color: #373737 !important;
}
.profile-account-bank-box {

    &-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            background: #0CAE96;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 7px;
            right: -15px;
        }

        .box-header-img {
            width: 40px;
            height: 40px;
            background-color: #373737;
            @include center;
            border-radius: 50%;

            img {
                width: 10px;
            }
        }


    }

    &-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        &-item {
            display: flex;

            @media (min-width : 992px) {}

            @media (max-width : 992px) {
                flex-direction: column;
            }

            align-items: center;
            font-size: 13.5px;
            justify-content: space-between;
            padding: 0 10px;

            >div:first-child {
                display: flex;

                align-items: center;
                justify-content: space-between;
                width: 100%;
            }

            p {

                margin: 7px 0px;

            }

            &:not(:last-child) {

                border-bottom: 1px solid #373737;


            }

            &-label {
                color: #0CAE96;
                white-space: nowrap;
            }

            &-information {
                display: flex;

                align-items: center;
                padding: 15px 0;
                width: 80%;

                @media (min-width : 992px) {
                    justify-content: space-between;
                }

                @media (max-width : 992px) {
                    justify-content: flex-end
                }

                &-value {
                    font-size: 12px;
                    text-align: right;
                    width: 100%;
                }

                &-options {
                    display: flex;

                    align-items: center;

                    .grey-icon {
                        margin-left: 30px;
                        width: 33px;
                        height: 33px;
                    }
                }
            }

        }
    }
}

.profile-account-info-box {

    &-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            background: #FFB300;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 7px;
            right: -15px;
        }

        .box-header-img {
            width: 40px;
            height: 40px;
            background-color: #373737;
            @include center;
            border-radius: 50%;

            img {
                width: 10px;
            }
        }


    }

    &-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        &-item {
            display: flex;

            @media (min-width : 992px) {}

            @media (max-width : 992px) {
                flex-direction: column;
            }

            align-items: center;
            font-size: 13.5px;
            justify-content: space-between;
            padding: 0 10px;

            >div:first-child {
                display: flex;

                align-items: center;
                justify-content: space-between;
                width: 100%;
            }

            p {

                margin: 7px 0px;

            }

            &:not(:last-child) {

                border-bottom: 1px solid #373737;


            }

            &-label {
                color: #FFB300;
                white-space: nowrap;
            }

            &-information {
                display: flex;

                align-items: center;
                padding: 15px 0;
                width: 80%;

                @media (min-width : 992px) {
                    justify-content: space-between;
                }

                @media (max-width : 992px) {
                    justify-content: flex-end
                }

                &-value {
                    font-size: 12px;
                    text-align: right;
                    width: 100%;
                }

                &-options {
                    display: flex;

                    align-items: center;

                    .grey-icon {
                        margin-left: 30px;
                        width: 33px;
                        height: 33px;
                    }
                }
            }

        }
    }
}
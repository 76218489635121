

.stock-box {
    #progress-bar {
        margin: 0 20px;
    
        div:last-child {
            left: 16px;
        }
    
        code {
            color: #fff !important;
            font-size: 2.2rem;
    
            span {
                top: 10px !important;
            }
        }
    }
    
    font-size: 12px;

    &-head {
        position: relative;

        img {
            width: 20px;

        }

        .box-header-img {
            @include center;

            padding: 5px;
            background-color: #373737;
            border-radius: 50%;
            width: 45px;
            height: 45px;
        }

        &::before {
            content: "";
            position: absolute;
            background: #fff;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 10px;
            right: -15px;
        }
    }

    &-details {
        li {
            display: flex;
            justify-content: space-between;
            padding: 15px 0;

            &:not(:last-child) {
                border-bottom: 1px solid #393939;

            }

            .stock-box-details-key {
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    span {
                        margin-right: 5px;
                    }
                }

                .stock-box-details-dot {
                    width: 9px;
                    height: 9px;
                    margin-left: 15px;
                    border-radius: 3px
                }
            }

        }
    }
}
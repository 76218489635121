.BBit-default-box {
    padding: 0 !important;
    margin: 2rem 1rem;

    .BBit-default-box-header {
        padding: 10px 15px;

        &-title {
            // width: 360px;
            display: flex;
            justify-content: space-between;
        }
    }

    .box-header-img {
        width: 40px;
        height: 40px;
        background-color: #ffb300;
        @include center;
        border-radius: 50%;

        img {
            width: 13px;
        }
    }
}
.PMprice
{
    text-align: left;
}
.searchBar
{
    width: 100%;
    padding: 5px;
    padding-right: 15px;
    border-radius: 10px;
    border: 2px solid #aaa;
    font-family: 'Samim';
    background-color: #333;
    color: #fff;
}
.txt-left
{
    text-align: left;
}
.title-td {
    justify-content: flex-start;

    img {
        width: 23px;
        margin-left: 5px;
    }
}
.table-head1 {
    margin-left: 0px;
    border-bottom-left-radius: 0px;
    display: grid;
    padding: 5px 0px;
    font-size: 1.2rem;
    align-items: center;
    font-weight: normal;
    grid-template-columns: 11% 83%;
    text-align: center;
    grid-gap: 1rem;
    background-image: linear-gradient(to right, #3a3a3a, rgba(47, 49, 50, 0));
}
.coinname
{
    grid-template-columns:  28px 80px 120px 60px !important;
}
.pishnahadNew
{
    grid-template-columns: 86px 70px !important;
}
.pishnahadNew1
{
    grid-template-columns: 98px 98px !important;
    text-align: left;

}
.table-head {
    margin-left: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0rem;

    display: grid;
    padding: 5px 0px;
    justify-content: space-around;
    font-size: 1.2rem;
    align-items: center;
    font-weight: normal;
    grid-template-columns: 98px 67px 58px;
    text-align: center;
    grid-gap: 1rem;

    background-image: linear-gradient(to right,
            rgba(58, 58, 58, 1),
            rgba(47, 49, 50, 0));
}

.table-body {
    display: grid;
    padding: 3px 0px;
    justify-content: space-around;
    font-size: 1.2rem;
    align-items: center;
    font-weight: normal;
    grid-template-columns: 98px 67px 70px;
    text-align: center;
    grid-gap: 1rem;

    &:not(:last-child) {
        border-bottom: 1px solid #3a3a3a;
    }
}





.your-stock-box {
    background-color: #0255b4 !important;


    .box-header-img {
        width: 40px;
        height: 40px;
        background-color: #0093F3;
        @include center;
        border-radius: 50%;

        img {
            width: 13px;
        }
    }

    &-table-head {
        grid-template-columns: 87px 100px;
    margin: 0 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 15px;
    padding: 15px 20px;
    display: grid;
    justify-content: space-between;
    font-size: 1.3rem;
    text-align: center;
    margin-right: 0;
    background-image: linear-gradient(to right, #4290ea, rgba(0, 92, 197, 0));
    /* box-shadow: 2px -4px 5px #03039a; */
    max-width: 100%;
    margin-right: 0px;
    overflow: hidden;
    border-top-right-radius: 10px;
    }

    &-table-body {
        direction: rtl;
        display: grid;
        padding: 3px 20px;
        justify-content: space-between;
        font-size: 1.2rem;
        align-items: center;
        text-align: center;
        font-weight: normal;
        grid-template-columns: 100px 100px;
        justify-items: revert;

        &:not(:last-child) {
            border-bottom: 0.5px solid #7592b471;
        }

    }

    @media (min-width: 992px) {
        overflow: auto;
        margin: 0;

        &-details {
            overflow-y: scroll;
            height: 214px;
            overflow-x: hidden;
            direction: ltr;

            &::-webkit-scrollbar {
                width: 3px;
                border-radius: 1rem;
                height: 180px;
            }

            &::-webkit-scrollbar-track {
                background: rgb(39, 39, 39);
            }

            &::-webkit-scrollbar-thumb {

                background: #363636;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }


}



.your-last-deal-box {
    overflow: auto;


    .box-header-img {
        width: 40px;
        height: 40px;
        background-color: #069D87;
        @include center;
        border-radius: 50%;

        img {
            width: 13px;
        }
    }

    .table-body {
        direction: rtl;
    }

    @media (min-width: 992px) {
        margin: 0;

        &-details {
            overflow-y: scroll;
            height: 214px;
            overflow-x: hidden;
            direction: ltr;

            &::-webkit-scrollbar {
                width: 3px;
                border-radius: 1rem;
                height: 180px;
            }

            &::-webkit-scrollbar-track {
                background: rgb(39, 39, 39);
            }

            &::-webkit-scrollbar-thumb {

                background: #363636;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }

}

.store-box {
    overflow: auto;


    .table-body {
        direction: rtl;
        cursor: pointer;
    }

    @media (min-width: 992px) {
        margin: 0;

        &-details {
            overflow-y: scroll;
            height: 300px;
            overflow-x: hidden;
            direction: ltr;

            &::-webkit-scrollbar {
                width: 3px;
                border-radius: 1rem;
                height: 180px;
            }

            &::-webkit-scrollbar-track {
                background: rgb(39, 39, 39);
            }

            &::-webkit-scrollbar-thumb {

                background: #363636;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }

}
.trade-bitcoin-cash-box {
    padding: 0 !important;
    background-color: #202020;
    // height: 300px;
    overflow: hidden;

    &-header {
        padding: 15px 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #2a2a2a;

        &-title {
            min-width: 130px;

            h6 {
                font-size: 1.4rem;
                color: #fff;
            }
        }


    }

    &-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        list-style: none;

        li {
            display: flex;
            padding: 0 10px;

            &:not(:last-child) {
                border-left: 1px solid #2a2a2a;
            }

            p {
                margin: 0 2px;
                color: #d4d3d3;
            }
        }
    }

    &-widget {
        height: 500px;
    }

}
.setting-change-password-box {
    &-head {
        margin-bottom: 30px;
        position: relative;

        .box-header-img {
            width: 40px;
            height: 40px;
            background-color: #373737;
            @include center;
            border-radius: 50%;

            img {
                width: 12px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            background: #099CFE;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 7px;
            right: -15px;

        }
    }

    &-form {
        button {
            width: 100%;
            background-color: #099CFE;
            border-radius: 5px;
            border: none;
            width: 100%;
            padding: 10px 20px;
            margin-top: 20px;
        }

        &-control {
            color: #fff;
            text-align: right;

            >div {
                position: relative;

                >div {
                    position: absolute;
                    top: 26px;
                    left: 6px;
                }
            }

            input {
                width: 100%;
                background-color: #414141;
                padding: 16px 20px;
                border-radius: 5px;
                width: 100%;
                text-align: right;
                border: none;
                margin: 12px 0;
                font-size: 12px;
                color: #fff;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    color: #fff !important;
                    opacity: 1 !important;
                    font-family: "Samim", sans-serif;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #fff !important;
                }

                &:placeholder-shown {
                    color: #fff !important;
                }

                &:required {}
            }

            label {
                font-size: 12px;

                span {
                    margin: 0 5px;
                }
            }
        }
    }
}

.setting-notification-box {
    &-head {
        margin-bottom: 30px;
        position: relative;

        .box-header-img {
            width: 40px;
            height: 40px;
            background-color: #373737;
            @include center;
            border-radius: 50%;

            img {
                width: 12px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            background: #FFB300;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 7px;
            right: -15px;
        }
    }

    &-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        padding: 20px 0;

        &:not(:last-child) {
            border-bottom: 1px solid #373737;
        }
    }
}

.setting-recognize-box {

    &-head {
        position: relative;
        margin-bottom: 10px;

        .box-header-img {
            width: 40px;
            height: 40px;
            background-color: #373737;
            @include center;
            border-radius: 50%;

            img {
                width: 12px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            background: #069D87;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 7px;
            right: -15px;
        }
    }

    &-details {
        display: flex;
        width: 100%;
        justify-content: space-between;

        @media (max-width: 992px) {
            flex-direction: column;
            justify-content: center;
        }

        align-items: center;
        font-size: 12px;
        padding: 20px 0;
        margin: 10px 0;

        button {
            padding: 15px 30px;
            width: 100%;
            max-width: 320px;
            border-radius: 5px;

            @media (max-width: 992px) {
                margin: 10px 0;
            }
        }
    }

}
.invite-friends {
    margin: 20px;

    .invite-code-box {
        &-head {
            display: flex;

            position: relative;

            @media (min-width : 992px) {
                align-items: center;
            }

            @media (max-width : 992px) {
                flex-direction: column;
            }


            &::before {
                content: "";
                position: absolute;
                background: #099CFE;
                width: 3px;
                height: 29px;
                border-radius: 28px;
                top: 7px;
                right: -15px;
            }

            &-description {
                margin: 0 10px;
                padding: 10px 10px;

                @media (min-width : 992px) {
                    border-right: 1px solid #4B4B4B;
                }

                font-size: 11.5px;
            }

            .box-header-img {
                width: 40px;
                height: 40px;
                @include center;
                border-radius: 50%;
                background-color: #3b3b3b;

                img {
                    width: 15px;
                }
            }
        }

        &-details {
            display: flex;
            flex-direction: column;
            align-items: center;

            &-range {
                padding: 0 10px;
                max-width: 430px;
                &-title {
                    font-size: 15px;
                    margin: 20px 0;
                    text-align: right;
                    margin-bottom: 50px;
                }

                &-slider {

                    display: grid;
                    grid-template-columns: repeat(6, 16.6%) !important;

                    p {
                        color: #717171;
                    }
                }
            }

            &-share {
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 430px;

                @media (min-width : 992px) {}

                @media (max-width : 992px) {
                    flex-direction: column;
                }

                &-item {
                    padding: 15px 25px;
                    width: 100%;
                    background-color: #2A2A2A;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 20px 7px;

                    p {
                        margin: 0 4px;
                        font-size: 14px;
                    }

                    &-value {
                        color: #099CFE;
                    }
                }
            }

            &-make-code {
                border-radius: 5px;
                width: 100%;
                max-width: 413px;
                padding: 15px 20px;
                background-color: #099CFE;
                font-size: 12px;
                margin: 20px 0;
                margin-bottom: 40px;
            }

            &-links {
                font-size: 13px;
                padding: 15px 15px;
                background-color: #2A2A2A;
                border-radius: 5px;
                width: 100%;
                display: flex;

                @media (min-width : 992px) {
                    align-items: center;
                }

                @media (max-width : 992px) {
                    flex-direction: column;
                }

                &-code {
                    display: flex;


                    @media (min-width : 992px) {
             
                        align-items: center;
                    }

                    @media (max-width : 992px) {
                        flex-direction: column;

                    }

                    .grey-icon {
                        margin: 0 10px;
                    }

                    &-key {
                        color: #0093F3;
                        margin-left: 20px;
                    }

                    &-value {
                        display: flex;
                        align-items: center;

                    }
                }
            }
        }

    }

    .invited-user-list-box {
        margin-top: 20px;

        &-head {
            position: relative;

            display: flex;


            @media (min-width : 992px) {
                justify-content: space-between;
                align-items: center;
            }

            @media (max-width : 992px) {
                flex-direction: column;
            }

            .box-header-img {
                background-color: #3b3b3b;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                width: 40px;
                height: 40px;

                img {
                    width: 15px !important;
                }
            }

            &::before {
                content: "";
                position: absolute;
                background: #FFB300;
                width: 3px;
                height: 29px;
                border-radius: 28px;
                top: 7px;
                right: -15px;
            }

            &-information {
                display: flex;
                font-size: 1.2rem;
                align-items: center;

                >p {
                    padding: 0 15px;
                }
            }


        }

        &-details {
            table {
                border-collapse: collapse;
                border-spacing: 2px;
                width: 100%;

                th {
                    text-align: right;
                }

                tr {
                    padding: 10px 0;

                    &:not(:last-child) {
                        box-shadow: 0px 2px 0px -0.5px #3a3a3a;

                    }


                    th {
                        color: #ffb300;
                        padding: 20px 15px;
                        font-size: 14.2px;

                    }

                    td {
                        color: #fff;
                        text-align: right;
                        padding: 18px 15px;
                        border-bottom: #393939;
                        font-size: 12px;


                    }
                }
            }

            &-flex {
                display: flex;
            }

        }
    }
}
.wallet-history-item {
    &:nth-child(even) {
        background-color: #444242;
    }

    &:nth-child(odd) {
        background-color: #696666;
    }

    .wallet-history-item-child {
        display: flex;
        padding: 2px;

        td {
            display: flex;
            justify-content: space-between;
            align-content: center;
            justify-items: center;
            padding: 10px 5px;
            font-size: 12px;
            width: 50%;

            p:nth-child(1) {
                color: #FFB300;
            }
        }
    }
}

.pmserch input {
    width: 100%;
    padding: 5px;
    font-family: 'Samim';
    border-radius: 12px;
    background-color: transparent;
    color: #fff;
    border: 2px solid #ccc;
    margin-bottom: 20px;
}

.pmaddr {
    max-width: 300px;
    overflow: hidden;
}

.pmbtn {
    width: 200px;
    margin-right: 170px;

}

.pmwal {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.pmttl {
    margin-right: -60px;
    width: max-content;
    font-size: 8pt;

}

.moamela_btn {
    background-color: #007BFF;

}

@media only screen and (max-width: 800px) {

    .wallet-withdrawal-deposit-box-info {
        display: block !important;
        font-size: 12px;
        padding: 15px 15px;
        margin: 10px 0;
        background-color: #2a2a2a;
        border-radius: 5px;
        width: 100%;

        text-align: center;
    }

    .addrwariz input {
        border: 2px solid #fff !important;
        margin-bottom: 10px;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        text-align: center;
        font-size: 15pt;
        background-color: transparent !important;
        border: 1px solid;
        color: aliceblue;
        font-size: 11px !important;
    }

    .addrwariz button {
        ursor: pointer;
        border: none;
        font-family: "Samim", sans-serif;
        color: #fff;
        white-space: nowrap;
        background-color: #ffb300;
        padding: 6px 15px;
        border-radius: 7px;
        width: auto;
        display: inline;
    }

    .copy-feedback {
        display: none;
    }

    .active {
        display: inline !important;
        color: #fff;
    }

    .addrwariz {
        width: 100% !important;
        padding: 20px;
    }
}

.addrwariz button {
    cursor: pointer;
    border: none;
    font-family: "Samim", sans-serif;
    color: #fff;
    white-space: nowrap;
    background-color: #ffb300;
    padding: 6px 15px;
    border-radius: 7px;
    width: auto;
    display: inline;
}

.copy-feedback {
    display: none;
}

.active {
    display: inline !important;
    color: #fff;
}

.addrwariz {
    width: 60%;
    padding: 20px 0px;

}

.addrwariz h1,
.addrwariz h5 {
    color: aliceblue;
    margin-bottom: 20px;

}

.addrwariz input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding-right: 10px;
    text-align: center;
    font-size: 15pt;
    background-color: darkcyan;
    border: none;
}

.wallet-box {
    margin: 2rem 1rem;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            background: #FFB300;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 5px;
            right: -15px;
        }

        .box-header-img {
            background-color: #3b3b3b;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            width: 40px;
            height: 40px;

            img {
                width: 15px !important;
            }
        }

        &-details {
            display: flex;
            font-size: 1.3rem !important;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            background-color: #f3a921;
            border-radius: 5px;
            text-align: center;
            margin-bottom: 10px;

        }
    }

    &-details {
        &-head {
            align-items: center;
            padding: 2rem;
            display: grid;
            grid-template-columns: 133px auto auto 50px;;


            padding: 10px 26px;
            font-size: 14px;

            text-align: center;

            p {
                color: #ffb300;


            }
        }

        &-item {
            align-items: center;
            padding: 2rem;
            display: grid;
            grid-template-columns: 100px 180px 120px auto ;

            background-color: #373737;
            border-radius: 10px;
            margin: 10px 0;
            padding: 2rem;
            font-size: 13px;
            text-align: right;

            &-cost {
                display: flex;


                p {
                    margin: 0 2px;
                    font-family: monospace;
                    font-size: 10pt;
                }
            }

            .copy-text {
                cursor: pointer;
                direction: ltr;



                &-img {
                    padding: 15px;
                    @include center;
                    background-color: #099cfe;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    margin-right: 5px;

                    img {
                        width: 13px;
                    }
                }
            }
        }

        button {
            padding: 5px 10px;
            width: auto;
            border-radius: 5px;
            margin: 0 2px;
        }

        .my-wallet-item {
            display: flex;
            flex-direction: column;
            padding: 10px 0;

            .my-wallet-item-container {
                display: flex;
                justify-content: space-between;

                .my-wallet-item-options {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    button {
                        margin: 5px 0;
                    }
                }

                .my-wallet-item-details {
                    padding-top: 10px;

                    .my-wallet-item-details-title {
                        display: flex;
                        align-items: center;

                        p {
                            padding-right: 5px;
                        }
                    }

                    .my-wallet-item-details-item {
                        display: flex;
                        padding: 5px 0;

                        .my-wallet-item-details-item-value {
                            display: flex;
                            color: #fff;

                            p {
                                padding-right: 5px;
                            }
                        }

                        .my-wallet-item-details-item-key {
                            padding: 0 30px;

                            p {
                                color: #ffb300;
                            }

                        }
                    }
                }


            }

            .my-wallet-item-address {
                display: flex;
                justify-content: space-between;
                padding: 15px 30px;

                .my-wallet-item-address-value {
                    color: #fff;
                    display: flex;
                    align-items: center;

                    p {
                        padding-right: 5px;
                    }

                    .copy-text-img {
                        padding: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #414141;
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }
                }

                .my-wallet-item-address-key {
                    display: flex;
                    align-items: center;

                    p {
                        color: #ffb300;
                    }
                }

            }

            &:not(:last-child) {
                border-bottom: 1px solid #3a3a3a;
            }
        }



    }
}

.wallet-coin-history {
    margin: 20px 20px;
    padding: 0 !important;
    overflow: hidden;
    position: relative;


    &-head {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 992px) {
            flex-direction: column;
            align-items: center;

            >div:nth-child(1) {
                width: 100%;
                padding: 15px 0;
            }
        }

        &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 25px;
            right: 0;
            background: #FFB300;
        }

        .box-header-img {
            padding: 15px;
            @include center;
            background-color: #3b3b3b;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 5px;

            img {
                width: 15px;
            }
        }

        &-options {
            display: flex;
            align-items: center;

            >div {
                select {
                    width: 150px;
                    font-size: 11px;
                    margin: 0 10px;
                }
            }
        }

    }

    &-details {
        width: 100%;

        &-header {
            display: grid;
            // grid-template-columns: repeat(5, 1fr);
            margin: 0;
            padding: 20px 15px;

            p {
                text-align: center;
                font-size: 12px;
                color: #ffb300;
            }
        }

        &-header1 {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin: 0;
            padding: 10px 10px;

            p {
                text-align: center;
                font-size: 12px;
                color: #ffb300;
                margin: 2px 30px;
            }
        }

        &-item {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            text-align: center;
            margin: 0;
            padding: 20px 15px;

            p {
                text-align: center;
                font-size: 12px;
            }

            &:nth-child(even) {
                background-color: #343434;
            }

            &-value {
                display: flex;
                justify-content: center;
                align-items: center
            }
        }
    }
}

.wallet-coin-barcode {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    border-radius: 5px;
    background-color: #2A2A2A;

    &-head {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .barcod {
            width: 120px;
        }

        .barcode-link {
            margin: 15px 0;
            font-size: 12px;
        }

        .copy-text {
            display: flex;
        }

        .copy-text-img.grey-icon {
            margin: 0 auto;
        }
    }
}

.wallet-coin-up-box {
    margin: 20px;

    &-head {
        position: relative;
        display: flex;
        justify-content: space-between;

        &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 5px;
            right: -16px;
            background: #FFB300;
        }

        .box-header-img {
            padding: 15px;
            @include center;
            background-color: #3b3b3b;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 5px;

            img {
                width: 15px;
            }
        }
    }

    &-item {

        display: flex;
        padding: 10px;
        background-color: #2A2A2A;
        justify-content: space-between;
        border-radius: 5px;
        margin: 20px 0;

        @media (max-width: 992px) {
            flex-direction: column;
            align-items: center;
        }

        >div {
            @media (max-width: 992px) {
                width: 100%;
                justify-content: space-between;
            }

        }

        >div:nth-child(1) {
            @media (max-width: 992px) {
                width: 100%;
                justify-content: left;
            }

        }

        &-info {
            .title-td {
                img {
                    width: 18px !important;
                }
            }

            &-cost {
                margin: 0 10px;
                font-size: 12px;


            }
        }

        .btn {
            padding: 10px 15px;
            border-radius: 5px;
            width: 140px;
            margin: 0 5px;
        }



    }
}


.wallet-withdrawal-deposit-box {
    margin: 20px;

    &-head {
        position: relative;
        display: flex;
        justify-content: space-between;

        &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 29px;
            border-radius: 28px;
            top: 5px;
            right: -16px;
            background: #FFB300;
        }

        .box-header-img {
            padding: 15px;
            @include center;
            background-color: #3b3b3b;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 5px;

            img {
                width: 15px;
            }
        }

    }

    &-info {
        display: flex;
        font-size: 12px;
        padding: 15px 15px;
        margin: 10px 0;
        background-color: #563e01;
        border-radius: 5px;
        align-content: flex-start;

        p {
            margin-right: 50px;
        }
    }

    &-info-line {
        display: flex;
        align-items: center;
        padding: 20px 0;

        .spot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin: 0 39px;
        }

        .white-spot {
            background-color: #fff;
        }

        .yellow-spot {
            background-color: #ffb300;
        }

        p {
            font-size: 12px;
        }
    }

    &-details {
        form {
            display: flex;
            flex-direction: column;
            margin: 50px auto;
            padding: 0 10px;
            width: 100%;
            max-width: 500px;
        }

        &-submit-btn {
            border-radius: 5px;
            border: none;
            width: 100%;
            padding: 10px 20px;
            margin-top: 20px;
        }

        &-info-wage {
            display: flex;
            align-items: center;

            button {
                background: transparent;
                border: 1px solid #3d3d3d;
            }

            @media (min-width: 992px) {}

            @media (max-width: 992px) {
                flex-direction: column;
                width: 100%;

                button {
                    width: 100%;
                }
            }

            &-details {
                display: flex;

                justify-content: space-between;

                font-size: 12px;


                @media (min-width: 992px) {
                    flex-direction: row;
                    padding-left: 30px;
                }

                @media (max-width: 992px) {
                    width: 100%;
                    flex-direction: column;

                }
            }

        }

        &-info {
            display: flex;
            align-items: center;
            width: 226px;
            justify-content: space-between;
            font-size: 12px;
            margin: 10px 0;


            &-cost {
                margin: 0 5px;

                span {
                    margin: 0 2px;
                }
            }
        }

        &-cards {
            display: flex;
            width: 100%;

            @media (max-width: 992px) {
                flex-direction: column;
            }

            span {
                background-color: transparent;
                border: 1px solid #3d3d3d;

                @media (min-width: 992px) {
                    margin-right: 15px;
                }

                @media (max-width: 992px) {
                    margin-top: 15px;
                }

                color: #fff;
                padding: 10px 30px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                // width: 100%;
                text-align: center;

                &:focus {
                    background-color: #3a3a3a;

                }

                img {
                    width: 15px;
                    margin-left: 15px;
                }
            }

            button {
                background-color: transparent;
                border: 1px solid #3d3d3d;

                @media (min-width: 992px) {
                    margin-right: 15px;
                }

                @media (max-width: 992px) {
                    margin-top: 15px;
                }

                color: #fff;
                padding: 10px 30px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                // width: 100%;
                text-align: center;

                &:focus {
                    background-color: #3a3a3a;

                }

                img {
                    width: 15px;
                    margin-left: 15px;
                }
            }
        }

        &-two-factor {
            position: relative;
            width: 100%;

            >div {
                top: 17px;
                left: 0;
                position: absolute;
                background-color: #484848;
                width: 35px;
                height: 35px;
            }
        }
    }
}



.video-btn {
    background-color: #3a3a3a;
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;
    width: 180px;
    text-align: center;

    &:focus {
        background-color: #3a3a3a;

    }

    img {
        width: 15px;
        margin-right: 15px;
    }
}


.relative {
    position: relative;
}


.wallet-input11 {
    background-color: #414141;
    padding: 1px 20px;
    border-radius: 5px;
    width: 100%;
    text-align: right;
    border: none;
    margin: 12px 0;
    font-size: 12px;
    color: #fff;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #fff !important;
        opacity: 1 !important;
        font-family: "Samim", sans-serif;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff !important;
    }

    &:placeholder-shown {
        color: #fff !important;
    }
}

.wallet-input11 input {
    padding: 10px;
    border-radius: 5px;
}

.wallet-input1 {
    background-color: #414141;
    padding: 1px 20px;
    border-radius: 5px;
    width: 100%;
    text-align: right;
    border: none;
    margin: 12px 0;
    font-size: 12px;
    color: #fff;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #fff !important;
        opacity: 1 !important;
        font-family: "Samim", sans-serif;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff !important;
    }

    &:placeholder-shown {
        color: #fff !important;
    }
}

.wallet-input {
    background-color: #414141;
    padding: 15px 39px;
    border-radius: 5px;
    width: 100%;
    text-align: right;
    border: none;
    font-size: 12px;
    color: #fff;
    margin-top: 5px;
    border: 1px solid;
    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #fff !important;
        opacity: 1 !important;
        font-family: "Samim", sans-serif;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff !important;
    }

    &:placeholder-shown {
        color: #fff !important;
    }
}

.wallet-select {
    background-color: #414141;
    color: #fff;
    text-align: right;
    font-family: "Samim", sans-serif;
    direction: rtl;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    border-left: 20px solid #414141;
    margin-top: 5px;
    border: 1px solid;

    svg {
        margin-left: 10px;
    }

    &:focus {
        outline: none;
    }
}

.wallet-range {
    width: 100%;

    direction: ltr;
    margin: 20px 0;

    input[type="range"] {
        margin: 10px 0;
        background-color: #3d3d3d;

        // -webkit-appearance: none;
        // -moz-appearance: none;
        // appearance: none;
        &:focus {
            outline: none;
        }
    }

    .wallet-range-persent {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 25%);

        .wallet-range-persent-div {
            display: flex;
            margin-left: -9px;
            justify-content: flex-start;
            flex-direction: column;
            cursor: grab;

            p {
                font-size: 11.5px;
            }

            .wallet-range-persent-div-line {
                width: 80%;
                height: 8px;
                margin-left: 9px;
                margin-bottom: 5px;
                background-color: #4A4A4A;
            }
        }
    }
}
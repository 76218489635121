.trade-active-request-box {
    padding: 0 !important;

    table {
        border-collapse: collapse;
        border-spacing: 2px;
        width: 100%;

        th {
            text-align: right;
        }

        tr {
            padding: 10px 0;

            &:not(:last-child) {
                box-shadow: 0px 2px 0px -0.5px #3a3a3a;

            }


            th {
                color: #ffb300;
                padding: 20px 15px;
                font-size: 14.2px;

            }

            td {
                color: #fff;
                text-align: right;
                padding: 18px 15px;
                border-bottom: #393939;
                font-size: 12px;


            }
        }
    }

    .cancel-btn {
        padding: 5px 10px;
        background-color: rgba($color: #E53939, $alpha:0.14);
        border-radius: 3px;
        font-size: 12px;

    }

    .CircularProgressbar {
        width: 44px !important;
    }
}
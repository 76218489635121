
.wallet-barcode-tab{
    .MuiTab-textColorPrimary.Mui-selected {
        color: #099CFE;
    }
    .MuiTabs-indicator{
        background-color: #099CFE;

    }
    .WalletBitCoinBarcode-div{
        margin: 40px 0;
    }
}
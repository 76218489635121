.card {
  transform: scale(1);
  transition: all 0.6s ease-in-out;

  .user__comment {
    font-size: 1.4rem;
    text-align: right;
    padding-bottom: 8rem;
  }

  .user__details {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .user-image {
      width: 5rem;
    }

    .user-info {
      text-align: right;
      margin: 0 1rem;
      font-size: 1.3rem;
    }

    .user-name {
      margin: 5px 0;
    }
  }
}

.cards-slider-wrapper {
  margin: 15px 0px 0px 0;
  display: flex;
  flex-direction: row-reverse;
  transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.cards-slider-wrapper .card {
  flex: 1;
  min-width: 24rem;
  height: 12rem;
  opacity: 1;
  margin-left: 1rem;
  transform: scale(1);
  transition: opacity 300ms linear, border-color 300ms linear,
    background-color 300ms linear,
    transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  @include respond(tab-land) {
    min-width: 32rem;
  }

  @include respond(tab-port) {
    min-width: 28rem;
  }
}

.cards-slider-wrapper .card .details {
  transition: opacity 150ms linear;
}

.feedabacklist-btnBox {
  position: relative;

  button {
    outline: none;
    border: none;
    padding: 1rem;
    margin: 5px;
    border-radius: 10px;
    position: absolute;
    z-index: 99999999;
    top: 80px;
    background-color: #484848;
    border-radius: 50%;
    width: 40px;
    height: 40px;

    &:hover {}

    &:disabled {
      display: none;
    }
  }

  .btn-prev {
    right: 0;

    img {
      transform: rotate(180deg);
    }
  }

  .btn-next {
    left: 0;
  }
}

.feedabacklist-sec {
  padding: 3rem;
  position: relative;
  margin-bottom: 15rem;

  &::before {
    content: "";
    background-color: #ebeded;
    position: absolute;
    bottom: -104px;
    left: -215px;
    width: 178rem;
    height: 100%;
    border-radius: 50%;

    @include respond(tab-port) {
      width: 160rem;
    }
  }
}

.slider-header-box {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;

  p {
    font-size: 1.3rem;
  }
}

.cost-info {
  @include center;
  flex-direction: column;
}

.cost-row {
  font-size: 1.4rem;
  width: 80%;
  margin: 4px 0;
  display: flex;
  justify-content: space-around;
}

.cost-row-cost {
  display: flex;
  flex-direction: row;
  margin-right: 21px;

  span {
    margin-right: 5px;
  }
}

.feedabacklist {
  direction: ltr;
  margin-right: 2rem;
}

.slider-header-box-persent {
  img {
    margin-left: 5px;
  }

}